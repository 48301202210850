import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import { language } from "./helpers/Constants";
import { getBrowserLanguage } from "./helpers/GenericHelper";
import languageCZ from "./locate/cz/translate.json";
import languageDE from "./locate/de/translate.json";
import languageDK from "./locate/dk/translate.json";
import languageEL from "./locate/el/translate.json";
import languageEN from "./locate/en/translate.json";
import languageES from "./locate/es/translate.json";
import languageET from "./locate/et/translate.json";
import languageFI from "./locate/fi/translate.json";
import languageFR_BE from "./locate/fr-be/translate.json";
import languageFR_FE from "./locate/fr-fe/translate.json";
import languageFR from "./locate/fr/translate.json";
import languageHR from "./locate/hr/translate.json";
import languageIT from "./locate/it/translate.json";
import languageNL from "./locate/nl/translate.json";
import languageNO from "./locate/no/translate.json";
import languagePL from "./locate/pl/translate.json";
import languagePT from "./locate/pt/translate.json";
import languageRU from "./locate/ru/translate.json";
import languageSE from "./locate/se/translate.json";
import languageSL from "./locate/sl/translate.json";
import languageEN_PR from "./locate/en-pr/translate.json";
import languageDE_PR from "./locate/de-pr/translate.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      de: languageDE,
      en_pr: languageEN_PR,
      de_pr: languageDE_PR,
      nl: languageNL,
      fr: languageFR,
      se: languageSE,
      fr_be: languageFR_BE,
      fr_fe: languageFR_FE,
      no: languageNO,
      fi: languageFI,
      it: languageIT,
      es: languageES,
      ru: languageRU,
      cz: languageCZ,
      dk: languageDK,
      el: languageEL,
      et: languageET,
      hr: languageHR,
      pl: languagePL,
      pt: languagePT,
      sl: languageSL,
    },
    /* default language when load the website in browser */
    /* if navigator.language is in a lang-Country format, only the language part will be taken*/
    lng:
      Cookies.get(language) === undefined
        ? getBrowserLanguage(navigator.language.split("-")[0])
        : Cookies.get(language),
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
